import { ChevronDown } from "react-feather";
import styles from "../communication-chat.module.css";
import { useEffect, useState } from "react";

const TenantListunread = (props) => {
  const [tenantUnreadMessages, SetTenantUnreadMessages] = useState([]);
  const [unreadMessageCounter, setUnreadMessageCounter] = useState(0)

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return cleaned;
  };

  useEffect(() =>{
    console.log(props);
    const tenants =  props.unreadMessageList.filter((p) => p.type === 'Tenant')
    SetTenantUnreadMessages(tenants)
    const totalUnread = tenants.reduce((acc, message) => {
      return acc + message.unread_no;
    }, 0);
    setUnreadMessageCounter(totalUnread)
    

  },[props])

  return (
    <>
      
            <div
             
              onClick={() => props.toggleAccordionForTenantUnRead()}
              className={
                
                props.accordionOnForTenantUnRead
                  ? `${styles.accordionOn} ${styles.Open}`
                  : `${styles.accordionOn}`
              }
            >
              <p className={`${styles.ListAccoudionTitle}`}>
                <ChevronDown /> Tenants ({unreadMessageCounter} messages)
              </p>
              {tenantUnreadMessages.length > 0  &&
                tenantUnreadMessages.map((t) => {
                  return (
                    <div key={t.id}>
                      <div
                        className={
                          t.id === props?.prospectTenantDetails?.id
                            ? `${styles.activeTenant} ${styles.CommuListSec}`
                            : `${styles.CommuListSec}`
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setDetails(t);
                        }}
                      >
                        <div className={`${styles.CommuListDeSec}`}>
                          <p className={`${styles.ProsTenantList}`}>
                            <span>
                              {t?.first_name}&nbsp;{t?.last_name}
                            </span>
                            <img
                              src="/static/img/verified.svg"
                              className={`${styles.VarifiedBadgeTenant}`}
                              alt=""
                            />
                          </p>
                          <p className={`${styles.ProsTenantListDe}`}>
                          <span>{t?.property_name}</span>
                            <span>{t?.apt_no}</span>
                            
                          </p>
                        </div>
                        <div className={`${styles.CommuListStatusSec}`}>
                          <span className={`${styles.PropertyGroupNotiCount}`}>
                          {t?.unread_no}
                          </span>
                        </div>
                      </div>

                      {/* <p
                        onClick={(e) => {
                          e.stopPropagation();
                          props.setDetails(t);
                        }}
                        className={
                          t.id === props?.prospectTenantDetails?.id
                            ? `${styles.activeTenant} ${styles.ProsTenantList}`
                            : `${styles.ProsTenantList}`
                        }
                      >
                        <span className={`${styles.Unit}`}>{t.apt_no}:</span>
                        <span className={`${styles.Name}`}>
                          {t?.first_name}&nbsp;{t?.last_name}
                        </span>{" "}
                        <span className={`${styles.Phone}`}>
                          {formatPhoneNumber(t.phone)}
                        </span>
                      </p> */}
                    </div>
                  );
                })}
              {tenantUnreadMessages.length === 0 && (
                <div className={`${styles.NoDataSecOnlyText}`}>
                  No tenant here!
                </div>
              )}
            </div>
          
       
    </>
  );
};

export default TenantListunread;
