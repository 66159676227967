import React from "react";
import async from "../components/Async";
import { Sliders, Users, LogOut, Home, Briefcase, MessageSquare, CreditCard, Smartphone, Search, FileText, Calendar as CalendarIcon } from "react-feather";

const HomeDefault = async(() => import("../pages/home/home"));
const Blogs = async(() => import("../pages/blogs/blogs"));
const ContactUs = async(() => import("../pages/contactus/contactus"));
const Privacy = async(() => import("../pages/privacy/privacy"));
const EULA = async(() => import("../pages/eula/eula"));

const RentalApplication = async(() => import("../pages/rental-application/rental-application"));
const RentalApplication2 = async(() => import("../pages/rental-application/rental-application-2"));
const RentalApplicationApplication2 = async(() => import("../pages/rental-application/rental-application-application-2"));
const RentalApplicationSuccess = async(() => import("../pages/rental-application/rental-application-success"));
const RentalApplicationError = async(() => import("../pages/rental-application/rental-application-error"));
const RentalApplicationInvalid = async(() => import("../pages/rental-application/rental-application-invalid"));
const RentalApplicationalreadySubmitted = async(() => import("../pages/rental-application/rental-application-already-subimitted"));
const RentalApplicationaLinkExpired = async(() => import("../pages/rental-application/rental-application-link-expired"));
const RentalApplicationPayment = async(() => import("../pages/rental-application/rental-application-payment"));

const AdminIndex = async(() => import("../pages/admin/index"));
const Dashboard = async(() => import("../pages/dashboards/Default"));
const Properties = async(() => import("../pages/properties/properties"));
const PropertiesDetails = async(() => import("../pages/properties/propertydetails"));
const UserList = async(() => import("../pages/users/userlist"));
const TenantList = async(() => import("../pages/tenants/tenantlist"));
const TenantDetails = async(() => import("../pages/tenants/tenantdetails"));
const MaintenanceRequest = async(() => import("../pages/maintenancerequest/maintenancerequest"));
const MaintenanceRequestAdd = async(() => import("../pages/maintenancerequest/maintenancerequestadd"));
const MaintenanceRequestDetails = async(() => import("../pages/maintenancerequest/maintenancerequestdetails"));
const Complaints = async(() => import("../pages/complaints/complaints"));
const ComplaintsAdd = async(() => import("../pages/complaints/complaintsadd"));
const ComplaintsDetails = async(() => import("../pages/complaints/complaintsdetails"));
const Violations = async(() => import("../pages/violations/violations"));
const ViolationsAdd = async(() => import("../pages/violations/violationsadd"));
const ViolationsDetails = async(() => import("../pages/violations/violationsdetails"));
const Communication = async(() => import("../pages/communication/communication"));
const CommunicationProspect = async(() => import("../pages/communication-prospect/communication-prospect"));
const Leases = async(() => import("../pages/payment/Leases"));
const LeaseDetails = async(() => import("../pages/payment/LeaseDetails"));
const LeaseSignSuccessAdmin = async(() => import("../pages/payment/LeaseSignSuccessAdmin"));
const LeaseSignSuccessTenant = async(() => import("../pages/payment/LeaseSignSuccessTenant"));
const AddLeaseDocumentSuccess = async(() => import("../pages/payment/AddLeaseDocumentSuccess"));
const AddLeaseTemplateSuccess = async(() => import("../pages/payment/AddLeaseTemplateSuccess"));
const EditLeaseTemplateSuccess = async(() => import("../pages/payment/EditLeaseTemplateSuccess"));
const AddLease = async(() => import("../pages/payment/AddLease"));
const RenewLease = async(() => import("../pages/payment/RenewLease"));
const RentRoll = async(() => import("../pages/payment/RentRoll"));
const RentRollDetails = async(() => import("../pages/payment/RenRollDetails"));
const AddCredit = async(() => import("../pages/payment/AddCredit"));
const AddBill = async(() => import("../pages/payment/AddBill"));
const RecordPayment = async(() => import("../pages/payment/RecordPayment"));
const UpdateCredit = async(() => import("../pages/payment/AddCredit"));
const UpdateBill = async(() => import("../pages/payment/AddBill"));
const UpdatePayment = async(() => import("../pages/payment/RecordPayment"));
const SmartDevices = async(() => import("../pages/smartdevices/smartdevices"));
const SmartDevicesDetails = async(() => import("../pages/smartdevices/smartdevicedetails"));
//const SmartdeviceFloorMap = async(() => import("../pages/smartdevices/smartdevicefloormap"));
const SmartDevicesAlerts = async(() => import("../pages/smartdevices/smart-devices-alerts/smart-device-alerts"));
const Logs = async(() => import("../pages/logs/logs"));
const More = async(() => import("../pages/more/more"));
const LogOff = async(() => import("../pages/logoff/LogOff"));
const Notifications = async(() => import("../pages/notification/notifications"));
const PayNow = async(() => import("../pages/paynow/paynow"));
const PaymentSuccess = async(() => import("../pages/paynow/pay-success"));
const ProspectList = async(() => import("../pages/prospect/prospectlist"));
const ProspectDetails = async(() => import("../pages/prospect/prospectdetails"));
const ScheduleTour = async(() => import("../pages/schedule-tour/tour"));

const indexRoutes = {
  path: "/",
  component: HomeDefault,
};
const homeRoutes = {
  path: "/home",
  component: HomeDefault,
};
const blogsRoutes = {
  path: "/blogs",
  component: Blogs,
};
const contactUsRoutes = {
  path: "/contact-us",
  component: ContactUs,
};
const privacyRoutes = {
  path: "/privacy-policy",
  component: Privacy,
};
const eulaRoutes = {
  path: "/eula",
  component: EULA,
};
const RentalApplicationRoutesFront = {
  path: "/rental-application/:str",
  component: RentalApplication,
};
const RentalApplication2RoutesFront = {
  path: "/rental-application/re-application/:appStr/:str",
  component: RentalApplication2,
};
const RentalApplicationApplication2RoutesFront = {
  path: "/rental-application/application-2/:str",
  component: RentalApplicationApplication2,
};


const RentalApplicationSuccessRoutesFront = {
  path: "/rental-application-success/:id",
  component: RentalApplicationSuccess,
};
const RentalApplicationErrorRoutes = {
  path: "/rental-application-error/:id",
  component: RentalApplicationError,
};
const RentalApplicationInvalidRoutes = {
  path: "/rental-application-invalid",
  component: RentalApplicationInvalid,
};
const RentalApplicationalreadySubmittedRoutes = {
  path: "/rental-application-already-submitted/:id",
  component: RentalApplicationalreadySubmitted,
};
const RentalApplicationLinkExpiredRoutes = {
  path: "/rental-application-link-expired/:id",
  component: RentalApplicationaLinkExpired,
};
const RentalApplicationPaymentRoutes = {
  path: "/rental-application-payment/:str",
  component: RentalApplicationPayment,
};
const adminIndexRoutes = {
  id: "AdminIndex",
  path: "/admin",
  icon: <Sliders />,
  component: AdminIndex,
  children: null,
  permission: ["all:list"],
  alternatePaths: ["/dashboard"],
};
const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Sliders />,
  component: Dashboard,
  children: null,
  permission: ["dashboard:visit"],
  alternatePaths: ["/dashboard"],
};
const propertiesRoutes = {
  id: "Properties",
  path: "/properties/list",
  icon: <Home />,
  component: Properties,
  children: null,
  permission: ["property:show"],
  alternatePaths: ["/properties/list", "/properties/details/"],
};
const propertiesDetRoutes = {
  id: "PropertiesDetails",
  path: "/properties/details/:id",
  icon: <Home />,
  component: PropertiesDetails,
  children: null,
  permission: ["property:show"],
};

const peopleRoutes = {
  id: "People",
  path: "/people",
  icon: <Users />,
  permission: ["prospect:read", "tenant:show", "user:show"],
  alternatePaths: [
    "/people/prospects",
    "/people/prospects/details/",
    "/people/tenants",
    "/people/tenants/details/",
    "/people/users",
  ],
  children: [
    {
      path: "/people/prospects",
      name: "Prospects",
      component: ProspectList,
      permission: ["prospect:read"],
      alternatePaths: ["/people/prospects", "/people/prospects/details/"],
    },
    {
      path: "/people/tenants",
      name: "Tenants",
      component: TenantList,
      permission: ["tenant:show"],
      alternatePaths: ["/people/tenants", "/people/tenants/details/"],
    },
    {
      path: "/people/users",
      name: "Users",
      component: UserList,
      permission: ["user:show", "user:create", "user:update"],
      alternatePaths: [
        "/people/users",
      ],
    },
  ],
  component: null,
};

const tenantDetailsRoutes = {
  id: "TenantDetails",
  path: "/people/tenants/details/:id",
  icon: <Home />,
  component: TenantDetails,
  children: null,
  permission: ["tenant:show"],
};
const prospectDetailsRoutes = {
  id: "ProspectDetails",
  path: "/people/prospects/details/:id",
  icon: <Home />,
  component: ProspectDetails,
  children: null,
  permission: ["prospect:read"],
};
const caseRoutes = {
  id: "Cases",
  path: "/cases",
  icon: <Briefcase />,
  permission: ["request:show", "complaint:show", "violation:show"],
  alternatePaths: [
    "/cases/maintenance-request",
    "/cases/maintenance-request/add",
    "/cases/maintenance-request/edit/",
    "/cases/maintenance-request/details/",
    "/cases/complaints",
    "/cases/complaints/add",
    "/cases/complaints/edit/",
    "/cases/complaints/details/",
    "/cases/violations",
    "/cases/violations/add",
    "/cases/violations/edit/",
    "/cases/violations/details/",
  ],
  children: [
    {
      path: "/cases/maintenance-request",
      name: "Maintenance Request",
      component: MaintenanceRequest,
      permission: ["request:show"],
      alternatePaths: [
        "/cases/maintenance-request",
        "/cases/maintenance-request/add",
        "/cases/maintenance-request/edit/",
        "/cases/maintenance-request/details/",
      ],
    },
    {
      path: "/cases/complaints",
      name: "Complaints",
      component: Complaints,
      permission: ["complaint:show"],
      alternatePaths: [
        "/cases/complaints",
        "/cases/complaints/add",
        "/cases/complaints/edit/",
        "/cases/complaints/details/",
      ],
    },
    {
      path: "/cases/violations",
      name: "Violations",
      component: Violations,
      permission: ["violation:show"],
      alternatePaths: [
        "/cases/violations",
        "/cases/violations/add",
        "/cases/violations/edit/",
        "/cases/violations/details/",
      ],
    },
  ],
  component: null,
};
const maintenanceRequestAddRoutes = {
  id: "MaintenanceRequestAdd",
  path: "/cases/maintenance-request/add",
  icon: <Home />,
  component: MaintenanceRequestAdd,
  children: null,
  permission: ["request:create"],
};
const maintenanceRequestEditRoutes = {
  id: "MaintenanceRequestEdit",
  path: "/cases/maintenance-request/edit/:id",
  icon: <Home />,
  component: MaintenanceRequestAdd,
  children: null,
  permission: ["request:update"],
};

const maintenanceRequestDetRoutes = {
  id: "MaintenanceRequestDetails",
  path: "/cases/maintenance-request/details/:id",
  icon: <Home />,
  component: MaintenanceRequestDetails,
  children: null,
  permission: ["request:show"],
};

const complaintAddRoutes = {
  id: "ComplaintsAdd",
  path: "/cases/complaints/add",
  icon: <Home />,
  component: ComplaintsAdd,
  children: null,
  permission: ["complaint:create"],
};
const complaintEditRoutes = {
  id: "ComplaintsEdit",
  path: "/cases/complaints/edit/:id",
  icon: <Home />,
  component: ComplaintsAdd,
  children: null,
  permission: ["complaint:update"],
};

const complaintDetRoutes = {
  id: "ComplaintsDetails",
  path: "/cases/complaints/details/:id",
  icon: <Home />,
  component: ComplaintsDetails,
  children: null,
  permission: ["complaint:show"],
};

const violationAddRoutes = {
  id: "ViolationsAdd",
  path: "/cases/violations/add",
  icon: <Home />,
  component: ViolationsAdd,
  children: null,
  permission: ["violation:create"],
};
const violationEditRoutes = {
  id: "ViolationsEdit",
  path: "/cases/violations/edit/:id",
  icon: <Home />,
  component: ViolationsAdd,
  children: null,
  permission: ["violation:update"],
};

const violationDetRoutes = {
  id: "ViolationsDetails",
  path: "/cases/violations/details/:id",
  icon: <Home />,
  component: ViolationsDetails,
  children: null,
  permission: ["violation:show"],
};

const CommunicationGroupRoutes = {
  id: "Communication",
  path: "/communication",
  icon: <MessageSquare />,
  permission: ["device:show"],
  component: null,
  children: [
    {
      path: "/tenant",
      name: "tenant",
      component: Communication,
      permission: ["device:show"],
      alternatePaths: ["/tenant"],
    },
    {
      path: "/prospect",
      name: "prospect",
      component: CommunicationProspect,
      permission: ["device:show"],
      alternatePaths: ["/prospect"],
    },
  ],
};

// const communicationRoutes = {
//   id: "Communication",
//   path: "/communication",
//   icon: <MessageSquare />,
//   component: Communication,
//   children: null,
//   permission: [],
//   alternatePaths: ["/communication"],
// };
// const communicationProspectRoutes = {
//   id: "CommunicationProspect",
//   path: "/communication/prospect",
//   icon: <MessageSquare />,
//   component: CommunicationProspect,
//   children: null,
//   permission: [],
//   alternatePaths: ["/communication/prospect"],
// };
const LeaseRoutes = {
  id: "Leases",
  path: "/leases",
  icon: <FileText />,
  component: Leases,
  permission: ["lease:cud"],
  children: null,
  alternatePaths: [
    "/leases",
    "/leases/details/:id",
    "/leases/add",
    "/leases/add/:id",
    "/leases/renew/:id",
  ],
};

const ScheduleTourRoutes = {
  id: "Schedule",
  path: "/schedule",
  icon: <CalendarIcon />,
  component: ScheduleTour,
  permission: ["schedule-tour:view"],
  children: null,
  alternatePaths: ["/schedule"],
};

const addLeaseNewRoutes = {
  id: "addLeaseNewRoutes",
  path: "/leases/add/:id",
  icon: <Home />,
  component: Leases,
  children: null,
  permission: ["lease:cud"],
};

const paymentRoutes = {
  id: "Payments",
  path: "/payments",
  icon: <CreditCard />,
  component: RentRoll,
  permission: ["rent-roll:show"],
  alternatePaths: [
    "/payments",
    "/payment/rent-roll/:id/details",
    "/payment/rent-roll/:tenant_id/add-credit",
    "/payment/rent-roll/:tenant_id/add-bill",
    "/payment/rent-roll/:tenant_id/record-payment",
    "/payment/rent-roll/:tenant_id/update-credit/:id",
    "/payment/rent-roll/:tenant_id/update-bill/:id",
    "/payment/rent-roll/:tenant_id/update-payment/:id",
  ],
  children: null,
};
const rentRollDetRoutes = {
  id: "RentRollDetails",
  path: "/payment/rent-roll/:id/details",
  icon: <Home />,
  component: RentRollDetails,
  children: null,
  permission: ["rent-roll:show"],
};
const addCreditRoutes = {
  id: "addCreditRoutes",
  path: "/payment/rent-roll/:rent_roll_id/add-credit",
  icon: <Home />,
  component: AddCredit,
  children: null,
  permission: ["rent-roll:cud"],
};
const addBillRoutes = {
  id: "addBillRoutes",
  path: "/payment/rent-roll/:rent_roll_id/add-bill",
  icon: <Home />,
  component: AddBill,
  children: null,
  permission: ["rent-roll:cud"],
};
const recordPaymentRoutes = {
  id: "recordPaymentRoutes",
  path: "/payment/rent-roll/:rent_roll_id/record-payment",
  icon: <Home />,
  component: RecordPayment,
  children: null,
  permission: ["rent-roll:cud"],
};
const updateCreditRoutes = {
  id: "updateCreditRoutes",
  path: "/payment/rent-roll/:rent_roll_id/update-credit/:id",
  icon: <Home />,
  component: UpdateCredit,
  children: null,
  permission: ["rent-roll:cud"],
};
const updateBillRoutes = {
  id: "updateBillRoutes",
  path: "/payment/rent-roll/:rent_roll_id/update-bill/:id",
  icon: <Home />,
  component: UpdateBill,
  children: null,
  permission: ["rent-roll:cud"],
};
const updatePaymentRoutes = {
  id: "updatePaymentRoutes",
  path: "/payment/rent-roll/:rent_roll_id/update-payment/:id",
  icon: <Home />,
  component: UpdatePayment,
  children: null,
  permission: ["rent-roll:cud"],
};

const leaseDetRoutes = {
  id: "leaseDetRoutes",
  path: "/leases/details/:id",
  icon: <Home />,
  component: LeaseDetails,
  children: null,
  permission: ["lease:show"],
};

const leaseSignSuccessAdminRoutes = {
  id: "leaseSignSuccessAdminRoutes",
  path: "/lease-sign-success/admin",
  icon: <Home />,
  component: LeaseSignSuccessAdmin,
  children: null,
  permission: ["lease:show"],
};

const leaseSignSuccessTeantRoutes = {
  id: "leaseSignSuccessTenantRoutes",
  path: "/lease-sign-success/tenant",
  icon: <Home />,
  component: LeaseSignSuccessTenant,
  children: null,
  permission: ["lease:show"],
};

const addLeaseDocumentSuccessRoutes = {
  id: "addLeaseDocumentSuccessRoutes",
  path: "/document-add-success",
  icon: <Home />,
  component: AddLeaseDocumentSuccess,
  children: null,
  permission: [],
};

const addLeaseTemplateSuccessRoutes = {
  id: "addLeaseTemplateSuccessRoutes",
  path: "/template-add-success",
  icon: <Home />,
  component: AddLeaseTemplateSuccess,
  children: null,
  permission: [],
};

const editLeaseTemplateSuccessRoutes = {
  id: "editLeaseTemplateSuccessRoutes",
  path: "/template-edit-success",
  icon: <Home />,
  component: EditLeaseTemplateSuccess,
  children: null,
  permission: [],
};

const addLeaseRoutes = {
  id: "addLeaseRoutes",
  path: "/leases/add",
  icon: <Home />,
  component: AddLease,
  children: null,
  permission: ["lease:cud"],
};

const renewLeaseRoutes = {
  id: "renewLeaseRoutes",
  path: "/leases/renew/:id",
  icon: <Home />,
  component: RenewLease,
  children: null,
  permission: ["lease:cud"],
};

const smartDevicesRoutes = {
  id: "Smart Devices",
  path: "/smart-devices",
  icon: <Smartphone />,
  permission: ["device:show"],
  component: null,
  alternatePaths: ["/smart-devices/list", "/smart-devices/alerts", "/smart-devices/details/"],
  children: [
    {
      path: "/smart-devices/list",
      name: "Manage Devices",
      component: SmartDevices,
      permission: ["device:show"],
      alternatePaths: ["/smart-devices/list", "/smart-devices/details/"],
    },
    {
      path: "/smart-devices/alerts",
      name: "Device Alerts",
      component: SmartDevicesAlerts,
      permission: ["device:show"],
      alternatePaths: ["/smart-devices/alerts"],
    },
    /*{
      path: "/smart-devices/floor-map",
      name: "Floor Map",
      component: SmartdeviceFloorMap,
      permission: ["device:show"],
      alternatePaths: ["/smart-devices/floor-map"],
    },*/
  ],
};

const smartDevicesDetRoutes = {
  id: "SmartDevicesDetails",
  path: "/smart-devices/details/:id",
  icon: <Smartphone />,
  component: SmartDevicesDetails,
  children: null,
  permission: ["device:show"],
};

const logsRoutes = {
  id: "Logs",
  path: "/logs",
  icon: <Search />,
  component: Logs,
  children: null,
  permission: ["logs:show"],
  alternatePaths: ["/logs"],
};

const moreRoutes = {
  id: "More",
  path: "/more",
  component: More,
  children: null,
  permission: [],
  alternatePaths: ["/more"],
};

const logoutRoutes = {
  id: "Logout",
  path: "/logout",
  component: LogOff,
  icon: <LogOut />,
  children: null,
  permission: [],
  alternatePaths: ["/logout"],
};
const loginRoutes = {
  id: "Login",
  path: "/login",
  component: AdminIndex,
  children: null,
  permission: [],
  alternatePaths: ["/login"],
};

const notificationsRoutes = {
  id: "Notifications",
  path: "/notifications",
  icon: <Search />,
  component: Notifications,
  children: null,
  permission: [],
  alternatePaths: ["/notifications"],
};

const payNowRoutes = {
  path: "/pay/:token",
  component: PayNow,
};

const paymentSuccessRoutes = {
  path: "/payment-success",
  component: PaymentSuccess,
};

const paymentSuccessOldRoutes = {
  path: "/payment-success/:tenantId/:id",
  component: PaymentSuccess,
};
/*const floorRoutes = {
  path: "/floor",
  component: SmartdeviceFloorMap,
};*/
const alertsRoutes = {
  path: "/alerts",
  component: SmartDevicesAlerts,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  //indexRoutes,
  adminIndexRoutes,
  dashboardsRoutes,
  propertiesRoutes,
  
  peopleRoutes,
  caseRoutes,
  maintenanceRequestAddRoutes,
  maintenanceRequestEditRoutes,
  
  complaintAddRoutes,
  complaintEditRoutes,
  

  //   communicationRoutes,
  //   communicationProspectRoutes,
  violationAddRoutes,
  violationEditRoutes,
  
  LeaseRoutes,
  paymentRoutes,
  addLeaseRoutes,
  addLeaseNewRoutes,
  renewLeaseRoutes,
  
  rentRollDetRoutes,
  addCreditRoutes,
  addBillRoutes,
  recordPaymentRoutes,
  updateCreditRoutes,
  updateBillRoutes,
  updatePaymentRoutes,
  smartDevicesRoutes,
  CommunicationGroupRoutes,
  
  
  notificationsRoutes,
  logsRoutes,
  logoutRoutes,
  loginRoutes,
  ScheduleTourRoutes,
  moreRoutes,
];

export const dashboardDetailsLayoutRoutes = [
  propertiesDetRoutes,
  maintenanceRequestDetRoutes,
  complaintDetRoutes,
  violationDetRoutes,
  leaseDetRoutes,
  smartDevicesDetRoutes,
  prospectDetailsRoutes,
  tenantDetailsRoutes,
];

export const customLayoutRoutes = [
  indexRoutes,
  homeRoutes,
  blogsRoutes,
  contactUsRoutes,
  payNowRoutes,
  paymentSuccessRoutes,
  paymentSuccessOldRoutes,
  //floorRoutes,
  alertsRoutes,
  privacyRoutes,
  eulaRoutes,
  RentalApplicationRoutesFront,
  RentalApplication2RoutesFront,
  RentalApplicationApplication2RoutesFront,
  RentalApplicationSuccessRoutesFront,
  RentalApplicationErrorRoutes,
  RentalApplicationInvalidRoutes,
  RentalApplicationalreadySubmittedRoutes,
  RentalApplicationLinkExpiredRoutes,
  RentalApplicationPaymentRoutes,
  leaseSignSuccessTeantRoutes,
  leaseSignSuccessAdminRoutes,
  addLeaseDocumentSuccessRoutes,
  addLeaseTemplateSuccessRoutes,
  editLeaseTemplateSuccessRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [];

// Routes that are protected
export const protectedRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  propertiesRoutes,
  peopleRoutes,
  ScheduleTourRoutes,
  caseRoutes,
  //   CommunicationRoutes,
  // CommunicationGroupRoutes,
  //   communicationProspectRoutes,
  LeaseRoutes,
  paymentRoutes,
  smartDevicesRoutes,
  logsRoutes,
  logoutRoutes,
];
