import MessageFileViewer from "./messageFileViewer";
import styles from "../communication-chat.module.css";
import { useEffect, useRef } from "react";
import ContentLoader from "react-content-loader";
import { useHistory } from "react-router-dom";
import moment from "moment";
const ChatMessages = (props) => {
  const chatAreaRef = useRef(null);
  const history = useHistory();
  console.log(props);
  useEffect(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [props.chatMessagesList]);

  const ShowTenantProspectName = (value) => {
    const findName = props.prospectTenantNames.find(
      (pt) => "+1" + pt.phone === value
    );
    if (findName) {
      return findName.first_name + " " + findName.last_name;
    } else {
      return value;
    }
  };

  const navigateTo = (value) => {
    console.log(value);
    const findId = props.prospectTenantNames.find(
      (pt) =>
        "+1" + pt.phone === value ||
        pt.first_name + " " + pt.last_name === value
    );
    if (findId) {
      console.log(findId);
      if (findId.type === "Prospect") {
        history.push(`/people/prospects/details/${findId.prospect_id}`);
      } else {
        history.push(`/people/tenants/details/${findId.tenant_id}`);
      }
    }
  };

  return (
    <>
      {props.messageLoader && (
        <div className={`${styles.ChatBoxContentLoader}`}>
          <ContentLoader width={400} height={300}>
            <rect x="27" y="40" rx="3" ry="3" width="260" height="10"></rect>
            <rect x="27" y="60" rx="3" ry="3" width="240" height="10"></rect>
            <rect x="27" y="80" rx="3" ry="3" width="180" height="10"></rect>

            <rect x="127" y="140" rx="3" ry="3" width="240" height="10"></rect>
            <rect x="127" y="160" rx="3" ry="3" width="240" height="10"></rect>
            <rect x="127" y="180" rx="3" ry="3" width="120" height="10"></rect>

            <rect x="27" y="240" rx="3" ry="3" width="250" height="10"></rect>
            <rect x="27" y="260" rx="3" ry="3" width="230" height="10"></rect>
            <rect x="27" y="280" rx="3" ry="3" width="180" height="10"></rect>
          </ContentLoader>
        </div>
      )}

      <div
        ref={chatAreaRef}
        className={`${styles.ChatViewSec}`}
        style={{ overflowY: "scroll", maxHeight: "300px" }}
      >
        {props.chatMessagesList.length > 0 &&
          props.chatMessagesList.map((msg, i) => {
            return (
              <div
                className={
                  msg.senderId
                    ? `${styles.MessageBlog} ${styles.Receiver}`
                    : `${styles.MessageBlog}`
                }
                key={i}
              >
                <div className={`${styles.MessageBlogInner}`}>
                  <div className={`${styles.MessageBlogCon}`}>
                    {msg.senderId && (
                      <p className={`${styles.MessageName}`}>
                        {msg.author}{" "}
                        <span className={`${styles.MessageTime}`}>
                          {moment(msg.createdAt).format('MMMM Do, h:mm A')}
                        </span>
                      </p>
                    )}
                    {!msg.senderId && (
                      <p
                        onClick={() => navigateTo(msg.author)}
                        className={`${styles.MessageName}`}
                      >
                        {ShowTenantProspectName(msg.author)}
                        <span className={`${styles.MessageTime}`}>
                        {moment(msg.createdAt).format('MMMM Do, h:mm A')}
                        </span>
                      </p>
                    )}
                    <div className={`${styles.MessageDesMain}`}>
                      <p className={`${styles.MessageDes}`}>{msg.message}</p>
                      {msg.media && msg.media.length > 0 && (
                        <div className={`${styles.MessageFileSec}`}>
                          <MessageFileViewer
                            media={msg.media}
                          ></MessageFileViewer>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {!props.messageLoader && props.chatMessagesList.length === 0 && (
          <div className={`${styles.ChatBoxnoDataSec}`}>
            <img src="/static/img/chatIcon.png" alt="logo" />
            <p>Start a New Conversation</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatMessages;
