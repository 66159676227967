import { Box, Grid } from "@material-ui/core";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import styles from "../communication-chat.module.css";
import { Search } from "react-feather";

const ProspectTenantSearch = (props) => {
  console.log(props);
  const filterByCallback = (option, props) => {
    const searchText = props.text.toLowerCase();
    return (
      option.first_name?.toLowerCase().includes(searchText) ||
      option.last_name?.toLowerCase().includes(searchText) ||
      option.phone?.includes(searchText) ||
      option.property_name?.toLowerCase().includes(searchText) ||
      option.apt_no?.toLowerCase().includes(searchText)
    );
  };
  return (
    <div className={`${styles.SearchSecMain}`}>
      <div className={`${styles.SearchSecUnder} SearchSecUnder`}>
        <Search />
        <Typeahead
          id="search-tenants"
          labelKey={(option) => {
            if (option && option.first_name && option.last_name) {
              return option.first_name + " " + option.last_name;
            } else {
              return option.phone;
            }
          }}
          filterBy={(option, props) => filterByCallback(option, props)}
          options={props.typeaheadProspectTenantList}
          placeholder="Search"
          clearButton={true}
          flip={true}
          onChange={props.onSelectTypeahead}
          renderMenuItemChildren={(option) => (
            <div className={`${styles.Width100}`}>
              {option && option.first_name && option.last_name ? (
                <div className={`${styles.SearchTPDeRow}`}>
                  <span className={`${styles.SearchTPDeName}`}>
                    {option.first_name} {option.last_name}
                  </span>
                  <span className={`${styles.SearchTPDeDe}`}>
                    <span>{option.property_name}</span>
                    <span>{option.apt_no}</span>
                  </span>
                </div>
              ) : (
                <div>{option.phone}</div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ProspectTenantSearch;
